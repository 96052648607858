<template>
  <div class="px-4 pt-4 pb-2 overflow-auto" style="height: calc(100% - 16px)">
    <div class="row mx-n2">
      <div class="col-4 px-2">
        <OrderedProductPricing :product="product" />
        <ProductPurchasePeriod
          :first-order-date="firstOrderDate"
          :last-order-date="lastOrderDate"
          :loading="productPurchasePeriodLoading"
        />
      </div>

      <div class="col-8 px-2">
        <div class="row mx-n2">
          <div class="col px-2">
            <ProductDataCard :product-id="product.id" class="h-100" />
          </div>
          <div class="col px-2">
            <PriceChangeSlider
              :monthly-average-amount="monthlyAverageAmount"
              :monthly-average-quantity="monthlyAverageQuantity"
              :base-price="threeMonthAveragePrice"
              :minimum="-50"
              :maximum="50"
              show-marks
              class="h-100"
              @change="sliderValue = $event"
            />
          </div>
        </div>
        <AveragePriceChart
          :data="orderItemsAggregatedByMonth"
          :loading="orderItemsAggregatedByMonthLoading"
          :supplier-id="supplierId"
          :product-id="product.id"
          class="mt-4"
        />
        <OrderPurchasesChart
          :data="orderItemsAggregatedByMonth"
          :loading="orderItemsAggregatedByMonthLoading"
          :supplier-id="supplierId"
          :product-id="product.id"
          class="mt-4"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { DateTime } from 'luxon';
import { ProductDataCard } from '@/modules/order';
import { useProductPurchasePeriod, usePriceChangeSlider } from '@/modules/order/compositions/orderItemAggregation';
import PriceChangeSlider from '@/modules/order/components/PriceChangeSlider';
import { ProductPurchasePeriod } from '@/modules/products/index';
import OrderPurchasesChart from '@/modules/order/components/OrderPurchasesChart.vue';
import AveragePriceChart from '@/modules/order/components/AveragePriceChart.vue';
import { useTenancy } from '@/modules/auth';

import OrderedProductPricing from './OrderedProductPricing.vue';
import { useOrderItemAggregationByMonthWithIntegral } from '@/modules/order/compositions/orderItemAggregationsByMonthWithIntegral';

export default {
  components: {
    OrderedProductPricing,
    PriceChangeSlider,
    ProductPurchasePeriod,
    ProductDataCard,
    OrderPurchasesChart,
    AveragePriceChart,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { currentTenant } = useTenancy();
    const supplierId = computed(() => props.product.business.id);

    const businessId = computed(() => currentTenant.value.id);
    const endOfYear = DateTime.local().endOf('year').startOf('month');
    const startOfYear = endOfYear.startOf('year');

    const { aggregations: monthlyAverageAggregation } = usePriceChangeSlider(
      computed(() => ({
        businessId: businessId.value,
        supplierId: props.product.business?.id,
        productId: props.product.id,
        toDate: DateTime.local().toFormat('yyyy-MM-dd'),
        fromDate: DateTime.local().minus({ months: 3 }).startOf('month').toFormat('yyyy-MM-dd'),
      }))
    );

    const { aggregations: orderItemsAggregatedByMonth, loading: orderItemsAggregatedByMonthLoading } =
      useOrderItemAggregationByMonthWithIntegral(
        computed(() => ({
          tenantId: currentTenant.value.id,
          productId: props.product.id,
          fromDate: startOfYear.toFormat('yyyy-MM-dd'),
          toDate: endOfYear.toFormat('yyyy-MM-dd'),
          supplierId: supplierId.value,
          retrieveSpecific: false,
        }))
      );

    const { aggregations: productPurchasePeriod, loading: productPurchasePeriodLoading } = useProductPurchasePeriod(
      computed(() => ({
        businessId: currentTenant.value?.id,
        productId: props.product.id,
      }))
    );

    const monthlyAverageAmount = computed(
      () => monthlyAverageAggregation.value[0]?.monthlyAverageSumTotalNetPricePerUnit
    );
    const monthlyAverageQuantity = computed(() => monthlyAverageAggregation.value[0]?.monthlyAverageTotalQuantity);

    const threeMonthAveragePrice = computed(
      () => Math.round(monthlyAverageAggregation.value[0]?.pricedOrderItemAverage * 100) / 100
    );

    return {
      firstOrderDate: computed(() =>
        productPurchasePeriod.value && productPurchasePeriod.value.length > 0
          ? new Date(productPurchasePeriod.value[0].firstOrder.date)
          : null
      ),
      lastOrderDate: computed(() =>
        productPurchasePeriod.value && productPurchasePeriod.value.length > 0
          ? new Date(productPurchasePeriod.value[0].lastOrder.date)
          : null
      ),
      threeMonthAveragePrice,
      productPurchasePeriodLoading,
      monthlyAverageAmount,
      monthlyAverageQuantity,
      orderItemsAggregatedByMonth,
      orderItemsAggregatedByMonthLoading,
      supplierId,
    };
  },
};
</script>
