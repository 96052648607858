<template>
  <div class="card border flex-row align-items-center justify-content-center p-1">
    <div class="chevron" @click="$emit('backward', -1)">
      <ChevronIcon :direction="$direction === 'rtl' ? 'right' : 'left'" />
    </div>
    <div class="mx-2">{{ selectedMonthText }}</div>
    <div class="chevron" @click="$emit('forward', 1)">
      <ChevronIcon :direction="$direction === 'rtl' ? 'left' : 'right'" />
    </div>
  </div>
</template>

<script>
import { ChevronIcon } from '@/assets/icons';

export default {
  components: { ChevronIcon },
  props: {
    toDate: { type: Object, required: true }, // luxon date object
  },
  computed: {
    selectedMonthText() {
      const endDate = this.formatMonth(this.toDate.toJSDate());
      const startDate = this.formatMonth(this.toDate.minus({ years: 1 }).toJSDate());
      return [startDate, endDate].join(' - ');
    },
  },
  methods: {
    formatMonth(date) {
      return date.toLocaleString(this.$i18n.locale, { month: 'long', year: 'numeric' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.chevron {
  svg {
    border-radius: 36px;
  }
  :hover {
    background: $light-gray;
    color: $primary;
    cursor: pointer;
  }
}
</style>
