<template>
  <div v-loading="loading" class="card shadow mt-4">
    <div class="card-header fw-bold">
      {{ $t('productModal.ProductPurchasePeriod.purchasePeriod') }}
    </div>
    <div class="card-body">
      <div class="row pb-1">
        <p class="w-50">{{ $t('first purchased') }}</p>
        <p class="w-50 align-text-to-end">{{ firstOrderDate | moFormatDate('MMMM YYYY') }}</p>
      </div>
      <div class="row border-bottom" />
      <div class="row pt-1 pb-3">
        <p class="w-50">{{ $t('last purchased') }}</p>
        <p class="w-50 align-text-to-end">{{ lastOrderDate | moFormatDate('MMMM YYYY') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    firstOrderDate: { type: Date, default: null },
    lastOrderDate: { type: Date, default: null },
    loading: { type: Boolean, default: false },
  },
};
</script>

<style scoped lang="scss">
.border-bottom {
  margin: 0;
  background: var(--bs-card-border-color);
  height: 1px;
}
.align-text-to-end {
  text-align: end;
}
</style>
