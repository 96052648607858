<template>
  <el-dialog
    v-if="productId"
    visible
    append-to-body
    :show-close="false"
    :custom-class="`rounded product-modal mt-5 mb-0 ${loading ? 'bg-white' : ''}`"
    @close="onClose"
  >
    <div v-if="loading" class="d-flex justify-content-center h-100">
      <div v-loading="loading" />
    </div>
    <template v-if="!loading && product" #title>
      <div class="d-flex justify-content-between">
        <h2 class="d-flex align-items-center">
          <AdditionalNamesPopOver
            v-if="product.references.length > 1"
            :icon-size="16"
            :references="product.references"
          />
          <span v-if="product.references.length > 1" class="mx-1" />
          <span>
            {{ product.name }}
          </span>
        </h2>
        <Button type="icon" class="p-0" @click="onClose">
          <CloseIcon />
        </Button>
      </div>
      <div class="d-flex">
        <template v-if="product.sku">
          <p :style="{ direction: 'ltr' }">{{ product.sku }}</p>
          <p class="mx-1">·</p>
        </template>
        <p>{{ product.business.name }}</p>
      </div>
    </template>
    <template v-if="!loading && product">
      <Tabs :tabs="tabs" :active-tab.sync="activeTab" class="pt-4 px-5 bg-white" />
      <div style="height: calc(100% - 73px)">
        <ProductPurchaseTab v-if="activeTab === 0 && productId === product.id" :product="product" />
        <EventsHistoryTab v-if="activeTab === 1 && productId === product.id" :product="product" />
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { ref } from 'vue';

import { useProduct } from '@/modules/product';
import { Tabs, Button } from '@/modules/core/components';
import { CloseIcon } from '@/assets/icons';
import { AdditionalNamesPopOver } from '@/modules/products';

import EventsHistoryTab from './EventsHistoryTab';
import ProductPurchaseTab from './ProductPurchaseTab';

import { useModal } from '../compositions/modal';

export default {
  components: { Button, CloseIcon, ProductPurchaseTab, Tabs, AdditionalNamesPopOver, EventsHistoryTab },
  setup() {
    const { productId, close } = useModal();
    const { product, loading } = useProduct(productId);
    const activeTab = ref(0);
    const onClose = () => {
      close();
      activeTab.value = 0;
    };
    return {
      productId,
      product,
      loading,
      activeTab,
      onClose,
    };
  },
  computed: {
    tabs() {
      return [
        {
          text: this.$t('purchase'),
        },
        {
          text: this.$t('events history'),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep .product-modal {
  height: 95vh;
  width: 85vw;
  $header-height: 79px;

  background: $light-gray;

  .el-dialog__header {
    height: $header-height;
    border-radius: inherit;
    background: $white;
  }
  .el-dialog__body {
    height: calc(100% - $header-height);
    padding: 0;
  }
}
</style>
